<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <filters :filtros="fields" :is-add.sync="isAdd" ref="filters"> </filters>
      <b-card ref="filterContent" no-body class="sticky">
        <b-card-body>
          <b-row>
            <b-col lg="10" class="d-flex flex-column flex-lg-row justify-content-start">
              <div class="w-100 mb-1 mb-lg-0">
                <b-form-group label="Proyecto" label-for="project" class="mr-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="project"
                    :reduce="(proyectos) => proyectos.id"
                    placeholder="Proyecto"
                    v-model="project_id"
                    @input="selectProyect(project_id)"
                    class="select-obra"
                  >
                    <template v-slot:selected-option="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div
                class="w-100 d-flex align-items-center h-100 justify-content-center justify-content-lg-end mb-1 mb-lg-0 flex-wrap flex-md-nowrap gap-1"
              >
                <b-button
                  class="mr-2 mb-1 mb-md-0"
                  variant="primary"
                  @click="addRestric(project_id)"
                >
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
                </b-button>
                <b-button class="mr-2 mb-1 mb-md-0" variant="warning" @click="importData">
                  <span class="text-nowrap"> <feather-icon icon="DownloadIcon" /> Importar </span>
                </b-button>
                <b-button
                  variant="success"
                  class="mr-2 mb-1 mb-md-0"
                  @click="exportExcel(project_id)"
                >
                  <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> Exportar </span>
                </b-button>
                <!-- <b-button variant="danger" class="mr-2 mb-1 mb-md-0" @click="generateReport">
                  <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> PDF </span>
                </b-button> -->
              </div>
            </b-col>
            <!-- <b-col md="6">
              
            </b-col> -->
            <b-col lg="2">
              <div
                class="d-flex align-items-center h-100 justify-content-center justify-content-lg-end"
              >
                <b-button
                  @click="showFilters"
                  variant="primary"
                  class="text-left px-1 w-auto"
                  block
                  v-b-toggle="'accordion1'"
                >
                  Columnas
                  <feather-icon v-if="show" icon="PlusCircleIcon" size="15" />
                  <feather-icon v-else icon="MinusCircleIcon" size="15" />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="selectedRecords.arrayId.length > 0">
            <b-col sm="12" md="6">
              <div class="w-100 mb-1 mb-lg-0">
                <b-form-group label="Estado" label-for="status" class="mr-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estados"
                    label="text"
                    input-id="status"
                    :reduce="(estados) => estados.value"
                    placeholder="Estado"
                    v-model="status"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <div class="w-100 mb-1 mb-lg-0 d-flex align-items-center h-100">
                <b-button class="mr-2 mb-1 mb-md-0" variant="primary" @click="changeStatus()">
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Cambiar </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card no-body ref="tableCard">
        <div class="table-overflow">
          <b-table
            class="position-relative"
            empty-text="No existen"
            :fields="visibleFields"
            :hover="true"
            id="__BVID__185"
            :items="records"
            no-border-collapse
            ref="selectableTable"
            selectable
            show-empty
            @row-selected="onRowSelectedOrder"
            @sort-changed="sortChanged"
            @refreshed="fixedElements"
          >
            <!-- Column: Actions -->
            <template #head(row)="">
              <div class="d-flex gap-2">
                <b-form-checkbox
                  id="checkall"
                  :checked="checkAll"
                  @change="selectAll((checkAll = !checkAll))"
                />
              </div>
            </template>
            <template #cell(row)="data">
              <div style="width: 0px !important">
                <b-form-checkbox disabled :checked="selectedRecords.arrayId.includes(data.item)" />
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                class=""
                @click.prevent="showDates(data.item)"
                v-b-tooltip.noninteractive.hover.top="'Historial de Fechas'"
                variant="flat-secondary"
              >
                <feather-icon icon="CalendarIcon" />
              </b-button>
              <b-button
                size="sm"
                class=""
                @click.prevent="showTimeLine(data.item)"
                v-b-tooltip.noninteractive.hover.top="'Comentarios'"
                variant="flat-info"
              >
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
                size="sm"
                class=""
                @click.prevent="edit(data.item)"
                v-b-tooltip.noninteractive.hover.top="'Editar'"
                variant="flat-success"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                size="sm"
                class=""
                v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                @click="deleteAction(data.item)"
                variant="flat-danger"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
            <template #cell(date_identified)="data">
              <span>{{ validDate(data.item.date_identified) }}</span>
            </template>
            <template #cell(date_required)="data">
              <span>{{ validDate(data.item.date_required) }}</span>
            </template>
          </b-table>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-comment"
        ref="modal-comment"
        centered
        title="Comentarios"
        ok-only
        hide-footer
        :size="addComent && timeLine.length > 0 ? 'lg' : ''"
        @hide="closeComment"
      >
        <b-card-text class="card_content pb-2">
          <b-row>
            <b-col :md="timeLine.length > 0 ? '12' : '4'">
              <span>{{ title }}</span>
            </b-col>
            <b-col md="12" :class="timeLine.length > 0 ? 'text-right' : 'text-center'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="!addComent ? 'danger' : 'warning'"
                @click="addComent = !addComent"
              >
                <span v-if="!addComent">Agregar</span>
                <span v-else>Ocultar</span>
              </b-button>
            </b-col>
          </b-row>
          <hr v-if="timeLine.length > 0" />
          <div class="content_main">
            <b-row :class="{ content_form: timeLine.length == 0 }">
              <b-col
                :md="addComent && timeLine.length > 0 ? '6' : '12'"
                :class="{ content_timeline: timeLine.length > 0 }"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="(item, index) in timeLine"
                    :key="index"
                    variant="primary"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    >
                      <h6>
                        {{ item.user_id != null ? item.user.name : 'sistema de restricciones' }}
                      </h6>
                      <small class="text-muted">{{
                        moment(item.created_at).format('yyyy-MM-DD')
                      }}</small>
                    </div>
                    <p>{{ item.description }}</p>
                    <!-- <p>{{ item.description }}</p> -->
                    <!-- <p v-if="item.file_path != ''">
                      <b-img
                        :src="require('@/assets/images/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                      />
                      <a :href="item.file_path" target="_blank"
                        ><span class="align-bottom">Descargar</span></a
                      >
                    </p> -->
                  </app-timeline-item>
                </app-timeline>
              </b-col>
              <b-col :md="timeLine.length > 0 ? '6' : '12'" v-if="addComent">
                <validation-observer ref="addComentarios">
                  <b-form
                    :class="{ section_add: timeLine.length > 0 }"
                    @submit.prevent="addComment"
                  >
                    <b-row>
                      <b-col cols="12" md="12">
                        <b-form-group label="Comentario" label-for="comentario">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="comment"
                          >
                            <b-form-textarea
                              placeholder="Comentario"
                              id="comentario"
                              rows="3"
                              no-resize
                              v-model="comment"
                            />
                            <small v-if="errors.length > 0" class="text-danger"
                              >El campo es requerido</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      >Agregar</b-button
                    >
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-dates"
        ref="modal-dates"
        centered
        title="Historial de Cambio de Fechas"
        ok-only
        hide-footer
        size="lg"
      >
        <b-table
          class="position-relative"
          :hover="true"
          :items="dates"
          responsive
          :fields="fields3"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
          style="max-height: 305px !important; overflow-y: scroll"
        >
          <!-- Column: Actions -->
          <template #cell(created_at)="data">
            <span>{{ validDate(data.item.created_at) }}</span>
          </template>
          <template #cell(date_after)="data">
            <span>{{ validDate(data.item.date_after) }}</span>
          </template>
          <template #cell(date_before)="data">
            <span>{{ validDate(data.item.date_before) }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries1"
                  @change="changeSizePage1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements1 }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage1"
                :total-rows="totalElements1"
                :per-page="showEntrie1"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina1"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <!-- <div v-if="showPDF">
        <div
          class="d-flex flex-column justify-content-center w-100 text-center"
          id="element-to-print"
        >
          <div class="d-flex w-100 border border-2 mb-1">
            <b-row class="w-100">
              <b-col sm="7">
                <b-card no-body class="d-flex flex-column py-1 mb-0">
                  <h3><strong>REPORTE DE RESTRICCIONES</strong></h3>
                </b-card>
              </b-col>
              <b-col sm="5">
                <b-card no-body class="d-flex flex-column py-1 mb-0">
                  <h3>
                    TOTAL DE RESTRICCIONES: <strong>{{ totalElementExport }}</strong>
                  </h3>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <b-table
            class="position-relative"
            :hover="true"
            :items="totalData"
            responsive
            :fields="fields2"
            show-empty
            selectable
            empty-text="No existen"
            @sort-changed="sortChanged"
            no-border-collapse
            ref="selectableTable"
            @row-selected="onRowSelectedOrder"
            style="width: 100%"
          >
            <template #cell(date_identified)="data">
              <span>{{
                data.item.date_identified != null
                  ? moment(data.item.date_identified).format('yyyy-MM-DD')
                  : ''
              }}</span>
            </template>
            <template #cell(date_required)="data">
              <span>{{
                data.item.date_required != null
                  ? moment(data.item.date_required).format('yyyy-MM-DD')
                  : ''
              }}</span>
            </template>
            <template #cell(date_commited)="data">
              <span>{{
                data.item.date_commited != null
                  ? moment(data.item.date_commited).format('yyyy-MM-DD')
                  : ''
              }}</span>
            </template>
            <template #cell(date_released)="data">
              <span>{{
                data.item.date_released != null
                  ? moment(data.item.date_released).format('yyyy-MM-DD')
                  : ''
              }}</span>
            </template>
          </b-table>
        </div>
      </div> -->
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import ProjectsService from '@/services/ProjectsService'
import RfiService from '@/services/RfiService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { required } from '@validations'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import * as html2pdf from 'html2pdf.js'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import filters from './filters.vue'
const APIURL = process.env.APIURLFILE
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      status: '',
      required,
      apiurl: APIURL,
      showLoading: false,
      show: true,
      timeLine: [],
      title: '',
      comment: '',
      addComent: false,
      showPDF: false,
      fields: [
        { key: 'row', label: '', sortable: false, visible: true, thStyle: { width: '80px' } },
        { key: 'code', label: 'Id-RFI', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'version', label: 'Version', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'version', label: 'Version', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'id_transmittal_aconex', label: 'Id-Transmittal', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'reference', label: 'Referencia', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'status_send', label: 'Estado Envio', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'status_respond', label: 'Estado Respuesta', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'description', label: 'Descripción', sortable: false, visible: false, thStyle: { width: '500px' } },
        { key: 'detail', label: 'Detalle', sortable: false, visible: false, thStyle: { width: '250px' } },        
        { key: 'comments_transmittal_JOT', label: 'Comentario-Transmital-JOT', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'id_response_aconex', label: 'Id-Respuesta', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'response_detail', label: 'Detalle Respuesta', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'comments_response_JOT', label: 'Comentario-Respuesra-JOT', sortable: false, visible: false, thStyle: { width: '250px' } },        
        { key: 'owner.name', label: 'Dueño', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'co_owner.name', label: 'Co-Dueño', sortable: false, visible: false, thStyle: { width: '250px' } },        
        { key: 'date_identified', label: 'F. Identificada', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_required', label: 'F. Requerida', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_required', label: 'F. Requerida', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_send', label: 'F. Envio', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_respond', label: 'F. Respuesta', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'project.code', label: 'Proyecto', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'activity.code', label: 'Actividad', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'location_1.code', label: 'Localidad 1', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'location_2.code', label: 'Localidad 2', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'location_3.code', label: 'Localidad 3', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'location_4.code', label: 'Localidad 4', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'impact_cost_scope', label: 'Costo Mayor Metrado', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'impact_cost_element', label: 'Costo Mayor Elemento', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'impact_time', label: 'Mayor Plazo de Ejecución', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'impact_quality', label: 'Nuevas Consideraciones', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'actions', label: 'Acciones', visible: true, thStyle: { width: '300px' } }
      ],
      fields2: [
        {
          key: 'code',
          label: 'Id-Restricción',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: true,
          thStyle: { width: '250px' }
        },
        {
          key: 'owner.name',
          label: 'Dueño',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: false,
          thStyle: { width: '250px' }
        },
        // {
        //   key: 'description',
        //   label: 'Descripción',
        //   tdClass: 'pad-export',
        //   thClass: 'pad-export',
        //   sortable: false,
        //   visible: true
        // },
        {
          key: 'priority',
          label: 'Prioridad',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: true,
          thStyle: { width: '250px' }
        },
        {
          key: 'category.code',
          label: 'Categoria',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: true,
          thStyle: { width: '250px' }
        },
        {
          key: 'date_identified',
          label: 'F. Identificada',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: false,
          thStyle: { width: '250px' }
        },
        {
          key: 'date_required',
          label: 'F. Requerida',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: false,
          thStyle: { width: '250px' }
        },
        {
          key: 'date_commited',
          label: 'F. Comprometida',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: false,
          thStyle: { width: '250px' }
        },
        {
          key: 'date_released',
          label: 'F. Liberada',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: false,
          thStyle: { width: '250px' }
        },
        {
          key: 'status',
          label: 'Estado',
          tdClass: 'pad-export',
          thClass: 'pad-export',
          sortable: false,
          visible: true,
          thStyle: { width: '250px' }
        }
      ],
      fields3: [
        { key: 'created_at', label: 'Fecha', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'type', label: 'Cambio', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_before', label: 'Fecha Antes', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'date_after', label: 'Fecha Nueva', sortable: false, visible: false, thStyle: { width: '250px' } },
        { key: 'user.name', label: 'Usuario', sortable: false, visible: false, thStyle: { width: '250px' } }
        // { key: 'actions', label: 'Acciones', visible: true }
      ],
      date_start: new Date(),
      date_end: new Date(),
      estados: [
        {
          text: 'Provisional',
          value: 'Provisional'
        },
        {
          text: 'Rechazada',
          value: 'Rechazada'
        },
        {
          text: 'Oficial',
          value: 'Oficial'
        },
        {
          text: 'Liberada',
          value: 'Liberada'
        },
        {
          text: 'Liberada por Usuario',
          value: 'Liberada por Usuario'
        },
        {
          text: 'Cancelada',
          value: 'Cancelada'
        }
      ],
      project_id: JSON.parse(localStorage.getItem('project_id')),
      rfi_id: null,
      records: [],
      checkAll: false,
      selectedRecords: {
        arrayId: []
      },
      projectSelect: '',
      totalElementExport: 0,
      totalData: [],
      proyectos: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      currentPage1: 1,
      entries1: [10, 20, 50, 100],
      showEntrie1: 10,
      totalElements1: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      isAdd: false,
      dates: [],
      navbar: null,
      filterContent: null,
      tableCard: null,
      tableContainer: null,
      selectableTable: null,
      tableHead: null,
      ths: null,
      trs: null,
    }
  },
  components: {
    vSelect,
    filters,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    ValidationProvider,
    ValidationObserver,
    AppTimelineItem
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    }
  },
  mounted() {
    this.getSelect()
    this.selectProyect(this.project_id)
    // this.getDatosExport()

    this.navbar = document.querySelector(".navbar");
    this.filterContent = this.$refs.filterContent;
    this.tableContainer = this.$el.querySelector(".table-overflow");
    this.tableCard = this.$refs.tableCard;
    this.selectableTable = this.$refs.selectableTable.$el;
    this.tableHead = this.selectableTable.querySelector("thead");
    this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');

    this.setupScrollSync();
    new ResizeObserver(this.fixedElements).observe(this.tableCard);
  },
  watch: {
    records(newVal, oldVal) {
      this.$nextTick(() => {
        this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr');
        this.fixedElements()
      })
    },
    visibleFields(newVal, oldVal) {
      this.$nextTick(() => {
        this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');
        this.fixedElements()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleWindowScroll);
    window.removeEventListener("resize", this.fixedElements);
  },
  methods: {
    fixedElements() {
      if (!this.trs[0].classList.contains('b-table-empty-row')) {
        const thsTotalWidth = [...this.ths].reduce((acc, th) => acc + th.offsetWidth, 0);

        if (thsTotalWidth > this.tableCard.offsetWidth) {
          this.ths.forEach((th, index) => {
            th.style.flex = "0 0 " + th.offsetWidth + "px";
          });
        } else {
          this.ths.forEach((th, index) => {
            th.style.flex = "1 1 " + th.offsetWidth + "px";
          });
        }

        this.trs.forEach((tr, index) => {
          const tds = tr.querySelectorAll('td');

          this.ths.forEach((th, index) => {
            tds[index].style.width = th.offsetWidth + "px";

            if (thsTotalWidth > this.tableCard.offsetWidth) {
              tds[index].style.flex = "0 0 " + th.offsetWidth + "px";
            } else {
              tds[index].style.flex = "1 1 " + th.offsetWidth + "px";
            }
          });
        });
        
      } else {
        this.selectableTable.style.width = this.tableHead.querySelector('tr').offsetWidth + 1 + "px";
      }

      this.tableHead.style.width = this.tableCard.offsetWidth - 1 + "px";
      this.selectableTable.style.paddingTop = this.tableHead.offsetHeight + "px";
    },
    setupScrollSync() {
      this.tableHead.addEventListener("scroll", () => {
        this.tableContainer.scrollLeft = this.tableHead.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      this.tableContainer.addEventListener("scroll", () => {
        this.tableHead.scrollLeft = this.tableContainer.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      window.addEventListener("scroll", this.handleWindowScroll);
      window.addEventListener("resize", this.fixedElements);
    },
    handleWindowScroll() {
      this.filterContent.style.top = this.navbar.offsetHeight + "px";

      if (this.tableCard.offsetTop - this.navbar.offsetHeight - 7 - window.scrollY <= 0) {
        this.tableHead.classList.add("fixed");
        this.tableHead.style.top = this.navbar.offsetHeight + this.filterContent.offsetHeight + "px";
      } else {
        this.tableHead.classList.remove("fixed");
        this.tableHead.style.top = null;
      }
    },
    validDate(fecha) {
      fecha = new Date(fecha)
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },
    closeComment() {
      this.comment = ''
      this.addComent = false
    },
    selectProyect(item) {
     
      this.arrayFilters = []
      if (item != null) {
        this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: item })
      }
    
      this.getData()
    },
    selectAll(val) {
     
      if (val) {
       
        this.$refs.selectableTable.selectAllRows()
      } else {
        this.$refs.selectableTable.clearSelected()
      }
      //
    },
    onRowSelectedOrder(items) {
     
      this.selectedRecords.arrayId = items
     
    },
    showDates(item) {
     
      this.rfi_id = item.id
      this.$refs['modal-dates'].show()
      this.getDates(item.id)
    },
    async getDates(id) {
      const resp = await RfiService.getDates(id, this.$store)
     
      if (resp.status) {
        this.dates = resp.data
        this.totalElements1 = resp.data.length
      }
    },
    showTimeLine(item) {
     
      this.rfi_id = item.id
      this.$refs['modal-comment'].show()
      this.getTimeLine(item.id)
    },
    async getTimeLine(id) {
      const resp = await RfiService.getComments(id, this.$store)
    
      if (resp.status) {
        this.timeLine = resp.data
       
      }
    },
    async addComment() {
      this.$refs.addComentarios.validate().then(async (success) => {
        if (success) {
          this.isDisabled = true
          let datos = {}
          const userData = JSON.parse(localStorage.getItem('userData'))
          datos.rfi_id = this.rfi_id
          datos.description = this.comment
          datos.user_id = userData.id
        
          const respComment = await RfiService.saveComent(datos, this.$store)
          if (respComment.status) {
            this.$swal({
              title: 'Regitrado',
              text: 'El comentario ha sido registrado.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.addComent = false
            this.getData()
            this.$refs['modal-comment'].hide()
          } else {
            this.$swal({
              title: 'Error!',
              text: ' Hubo un error al registrar el comentario',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        }
      })
    },
    async changeStatus() {
     
      let filterArrays = []
      for (let index = 0; index < this.selectedRecords.arrayId.length; index++) {
        const element = this.selectedRecords.arrayId[index]
        filterArrays.push(element.id)
      }
      this.$swal({
        title: '¿Seguro que desea cambiar a esta(s) restriccion(es)?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          if (filterArrays.length > 0) {
            const resp = await RfiService.saveChangeStatus(
              {
                status: this.status,
                array_id: filterArrays
              },
              this.$store
            )
            if (resp.status) {
              this.currentPage = 1
              this.$swal({
                icon: 'success',
                title: 'Cambiado!',
                text: 'Los estados fueron cambiados.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
              this.getData()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al cambiar el estado de la restricción seleccionada.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            }
          }
        }
      })
    },
    showFilters() {
      this.isAdd = true
    },
    importData() {
      this.$router.push('/rfi/importar-datos')
    },
    async exportExcel(id) {
      if (id != null) {
        this.showLoading = true
        // const resp = await RestrictionsService.exportExcel(id,this.$store)
        // console.log('resp',resp)
        var fileLink = document.createElement('a')
        fileLink.href = this.apiurl + '/rfi/download/'+id
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()

        this.showLoading = false
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto',
          text: 'Seleccione un proyecto para exportar los datos.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    async generateReport() {
      this.showLoading = true
      this.showPDF = true
      await new Promise((r) => setTimeout(r, 300))
      var element = document.getElementById('element-to-print')
      var opt = {
        margin: 0.4,
        filename: 'Restricciones.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      }
      // element.style.display = 'none';
      // New Promise-based usage:
      await html2pdf().from(element).set(opt).save()
      this.showPDF = false
      this.showLoading = false
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    cambioPagina1(e) {
      this.currentPage1 = e
      this.getData()
    },
    changeSizePage1() {
      // this.getData()
    },
    addRestric(id) {
      if (id != null) {
        this.$router.push('/rfi/registro/' + id)
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto',
          text: 'Seleccione un proyecto para empezar.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    async getSelect() {
      const user = JSON.parse(localStorage.getItem('userData'))
      const url2 = `?limit=100`
      const respProyectos = await ProjectsService.getProyectos(url2, this.$store)
     
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
        // if (respProyectos.data.rows.length > 0) {
        //   this.project_id = respProyectos.data.rows[0].id
        // } else {
        //   if (user.role == 'planner') {
        //     this.project_id = 0
        //   }
        // } 
        // this.selectProyect(this.project_id)
      }
    },
    async getData() {
      this.showLoading = true
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
      const resp = await RfiService.getRfi(url, this.$store)
    
      if (resp.status) {
       
        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    historial(item) {
      this.$refs['modal-historial'].show()
    },
    edit(item) {
    
      this.$router.push('/rfi/editar/' + item.id)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este rfi?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      const resp = await RfiService.deleteRfi(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El RFI ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la rfi seleccionada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.pad-export {
  padding: 0.52rem 1rem !important;
}
.content_main {
  .content_timeline {
    max-height: 450px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .content_form {
    display: flex;
    justify-content: center;
  }
}
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

.sticky {
  position: sticky;
  z-index: 3;
}

.table-overflow {
  overflow-x: auto;
  width: 100%;

  thead {
    display: flex;
    overflow-x: auto;
    position: absolute;
    top: 0;

    &.fixed {
      position: fixed;
      transform: translateX(0px) !important;
      z-index: 2;
    }

    tr {
      display: flex;
      flex: 1 1 auto;

      th {
        flex: 0 0 auto;
        padding: 0.72rem 2rem !important;
      }
    }
  }

  tbody {
    overflow-x: auto;

    tr {
      display: flex;

      &.b-table-empty-row {
        td {
          flex: 0 0 100%;
        }
      }

      td {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
